import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SigninService } from 'src/app/services/signin.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { UserIdleService } from 'angular-user-idle';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit, OnDestroy {
  public active: any; res: any; public date: any; bal:any; timeout = false;
  constructor(public api: ApiService, private ser: SigninService, private _nav: Router, public toastr: ToastrService,  private userIdle: UserIdleService) { }

  ngOnInit() {
    this.init();
    this.active = this.ser.userDetails();
    this.date = JSON.parse(localStorage.getItem('Date'));
    // console.log(this.active);
    this.getClaims();

    //Start watching for user inactivity.
    this.startWatching();
    
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => { 
      // console.log(count); 
      this.timeout = true;
      this.showMsg('warning', 'Connection Deactivated', 'you have been idle for 20 minutes u will be Disconnected from server in 10second')
    });
    
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => { 
      // console.log('Time is up!'); 
      this.logOut(); 
    });
    this.userIdle.ping$.subscribe(() => {
      // console.log("PING");
      this.getRefreshToken();
    });
  }

  ngOnDestroy() {
    this.stop();
    this.stopWatching();
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
    this.timeout = false;
  }

  showMsg(type, title, message) {
    if (type === 'success') {
      this.toastr.success(message, title);
    } else if (type === 'warning') {
      this.toastr.warning(message, title);
    } else if (type === 'error') {
      this.toastr.error(message, title);
    }
  }

  getClaims() {
    this.ser.getActiveUser().subscribe((data: any) => {
      this.api.user = data.data; this.active = data.data;
      // this.showMsg('success', 'Claims', JSON.stringify(data));
    }, (err: HttpErrorResponse) => { /*this._nav.navigate(['/login']);*/ });
  }

  getRefreshToken() {
    this.ser.getRefresh().subscribe((data: any) => {
      localStorage.setItem('Token', JSON.stringify(data.access_token));
    }, (err: HttpErrorResponse) => { /*this._nav.navigate(['/login']);*/ });
  }

  logOut() {
    this.ser.logOut(this.active.unique_name).subscribe((data: any) => {
      localStorage.removeItem('Token'); this.stop();
      this.showMsg('success', 'Logout', 'You hav been Logout successfully @' + this.active.fullname);
      this._nav.navigate(['/login']);
    }, (err: HttpErrorResponse) => {
      localStorage.removeItem('Token'); this.stop()
      this._nav.navigate(['/login']);
    });
  }

  init() {
    this.active = { fullname: '', image: '', usertype: '', };
  }

}
